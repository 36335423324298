var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.cash,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id) + " ")]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.status) + " ")]
                },
              },
              {
                key: "item.operation_type",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.operation_type) + " ")]
                },
              },
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.amount) + " ")]
                },
              },
              {
                key: "item.currency",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.currency) + " ")]
                },
              },
              {
                key: "item.currency_amount",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.currency_amount) + " ")]
                },
              },
              {
                key: "item.commission",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.commission) + " ")]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.created_at)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.city",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.city) + " ")]
                },
              },
              {
                key: "item.address",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.address) + " ")]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    item.status === "Ожидание"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "green",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.at_work(item)
                              },
                            },
                          },
                          [_vm._v(" Взять в работу ")]
                        )
                      : _vm._e(),
                    item.status === "В работе"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "green",
                            attrs: { dense: "", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.success(item)
                              },
                            },
                          },
                          [_vm._v(" Наличные получены ")]
                        )
                      : _vm._e(),
                    item.status === "В работе"
                      ? _c("NumberEditDialog", {
                          attrs: {
                            caption: "Правка суммы",
                            title: "Правка суммы",
                            label: "Сумма",
                            tag: item,
                            value: item.currency_amount,
                          },
                          on: { ok: _vm.amount },
                        })
                      : _vm._e(),
                    _c("TextInputDialog", {
                      attrs: {
                        buttonclass: "red",
                        buttontext: "Отклонить",
                        caption: "Причина",
                        tag: item,
                      },
                      on: { ok: _vm.cancel },
                    }),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c("td", { attrs: { colspan: headers.length - 2 } }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `ID юзера: ${
                                item.history ? item.history.user_id : ""
                              }`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `nickname: ${
                                item.history?.user
                                  ? item.history.user.nickname
                                  : ""
                              }`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Баланс до: ${item.balance_old.toLocaleString(
                                "ru-RU",
                                { style: "currency", currency: "USD" }
                              )}`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Баланс после: ${item.balance_now.toLocaleString(
                                "ru-RU",
                                { style: "currency", currency: "USD" }
                              )}`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(`Контактные данные: ${item.contact}`) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(" " + _vm._s(`Код выдачи: ${item.code}`) + " "),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Забронированное время и дата: ${item.time} ${item.date} `
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Продать за наличные")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }